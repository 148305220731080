import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';
import '../../styles/hover-effect.css';

// Export Google Slides to PDF.
const exportPdf = (id: string) => `https://docs.google.com/presentation/d/${id}/export/pdf`;
const viewPptx = (id: string) => `https://docs.google.com/presentation/d/${id}/edit?usp=sharing&ouid=113527810443057925300&rtpof=true&sd=true`;

const LearnSciencePage = () => (
	<Layout title='Educational Resources'>
		<h2 className='px-3 mt-3 display-5 raleway-700 text-center'>Educational Resources</h2>
		<p className='px-3 my-3 col-md-8 mx-auto'>
			There’s more science going on in Earth’s orbit than you might think, and it’s not all planetary science and
			astrophysics! Microgravity lets us study a huge variety of scientific phenomena related to biology, fluid
			mechanics, and materials science.
		</p>
		<p className='px-3 my-3 col-md-8 mx-auto'>
			The ASGSR student group has developed some educational slides intended to introduce other students to space
			science concepts. These slides can be used during volunteer classroom visits or as part of a classroom
			curriculum.
		</p>
		<div className='row row-cols-2 row-cols-md-3 row-cols-lg-4'>
			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1xBn2i8TzN_MFn4Gf3hbje2RwTp_UKBAf')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/asgsr-intro.jpg'
							alt='Introduction to science in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Introduction to science in space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1axTJddHRGmuV5cQjGzGtKUAdMQWAdQHY')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/fluid-mechanics.jpg'
							alt='Fluid mechanics in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Fluid Mechanics in Space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1gzZZgvLBah5Kfgw_TPd4oht_eVQtLOdE')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/biophysics.jpg'
							alt='Biophysics in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Biophysics in space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1uXZw3G6lOHLxo3QhqGlwMDcbg5JfKUul')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/combustion.jpg'
							alt='Combustion in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Combustion in space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1TMHkU_NxTyd1VPMgBBkK4WRDFWqFyni3')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/plants.jpg'
							alt='Plants in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Plants in space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1dITzUOZjlLHdg81aDgdaryeDfN6HOGDj')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/physiology.jpg'
							alt='Physiology in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Physiology in space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1Fk_EIiSsHTI16BB7_bkS62TYfWTtddxP')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/data-analytics.jpg'
							alt='Data analytics and machine learning applied to space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Data Analytics and Machine Learning applied to space.</div>
					</div>
				</a>
			</div>

			<div className='col my-3'>
				<a target='_blank' href={viewPptx('1R4YfieRzSgpWIxwe0QDBbZXbh-s3pO3l')}>
					<div className='box'>
						<StaticImage
							src='../../images/learn/flames-and-fire.jpg'
							alt='Flames and fire in space.'
							layout='fullWidth'
							formats={['auto', 'webp']}
							placeholder='blurred'
							class='br img'
						/>
						<div className='text raleway-700'>Flames and fire in space.</div>
					</div>
				</a>
			</div>
		</div>

		<h3 className='px-3 display-7 mt-3 raleway-700 text-center'>More Resources</h3>
		<p className='px-3 col-md-8 mx-auto'>
			For more lesson plans and other resources for K-12 learning, see{' '}
			<a target='_blank' href='http://www.spacestationexplorers.org/lesson-plans/'>
				Space Station Explorers
			</a>{' '}
			by{' '}
			<a target='_blank' href='http://www.iss-casis.org/'>
				CASIS
			</a>
			.
		</p>

		<p className='px-3 col-md-8 mx-auto'>
			For more detailed information on space science, see the links below which will take you to relevant{' '}
			<a target='_blank' href='https://www.nasa.gov/connect/ebooks/iss_researchers_guides.html'>
				ISS researcher’s guides
			</a>{' '}
			in the various fields, which are published by NASA:
			<ul className='my-3 mb-5'>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_acceleration_environment_detail.html'
						target='_blank'
					>
						<b>Acceleration Environment</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_cellular_biology_detail.html'
						target='_blank'
					>
						<b>Cellular Biology</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_combustion_science_detail.html'
						target='_blank'
					>
						<b>Combustion Science</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_earth_observations_detail.html'
						target='_blank'
					>
						<b>Earth Observations</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_fluid_physics_detail.html'
						target='_blank'
					>
						<b>Fluid Physics</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_fruit_fly_research_detail.html'
						target='_blank'
					>
						<b>Fruit Fly Research</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_fundamental_physics_detail.html'
						target='_blank'
					>
						<b>Fundamental Physics</b>
					</a>
				</li>
				<li>
					<a target='_blank' href='https://www.nasa.gov/connect/ebooks/researchers-guide-to-genelab'>
						<b>GeneLab</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_human_research_detail.html'
						target='_blank'
					>
						<b>Human Research</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_macromolecular_crystal_growth_detail.html'
						target='_blank'
					>
						<b>Macromolecular Crystal Growth</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_microbial_research_detail.html'
						target='_blank'
					>
						<b>Microbial Research</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_microgravity_materials_detail.html'
						target='_blank'
					>
						<b>Microgravity Materials Research</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researcher-s-guide-to-physical-sciences-informatics-system'
						target='_blank'
					>
						<b>Physical Sciences Informatics System</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_plant_science_detail.html'
						target='_blank'
					>
						<b>Plant Science</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_rodent_research_detail.html'
						target='_blank'
					>
						<b>Rodent Research</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_space_environment_detail.html'
						target='_blank'
					>
						<b>Space Environmental Effects</b>
					</a>
				</li>
				<li>
					<a
						href='https://www.nasa.gov/connect/ebooks/researchers_guide_tech_demo_detail.html'
						target='_blank'
					>
						<b>Technology Demonstration</b>
					</a>
				</li>
			</ul>
		</p>
	</Layout>
);

export default LearnSciencePage;
